<template>
  <div id="payment">
    <pageHeader />

    <div class="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="9" class="pa-0" v-if="!$route.query.pageType">
            <h1 class="main-color mb-0">الدفع</h1>
            <hr class="main-hr" />
            <v-row>
              <v-col cols="12" sm="6">
                <div class="duration-btns d-block d-sm-none">
                  <v-btn
                    class="monthly"
                    @click="updatePlanDuration('monthly')"
                    :class="!annually ? 'active' : ''"
                    large
                    >شهري</v-btn
                  >
                  <v-btn
                    class="annual"
                    :class="annually ? 'active' : ''"
                    @click="updatePlanDuration('annually')"
                    large
                    >سنوي</v-btn
                  >
                </div>
                <h2 class="mr-4">وسائل الدفع</h2>
                <v-radio-group
                  row
                  v-model="paymentData.payment_method"
                  class="payment-methods"
                >
                  <v-radio label="visa" value="visa">
                    <template v-slot:label>
                      <img
                        src="../assets/visa.jpg"
                        width="60px"
                        alt="visa"
                        class="payment-method"
                        :class="
                          paymentData.payment_method == 'visa' ? 'active' : ''
                        "
                      />
                    </template>
                  </v-radio>
                  <v-radio label="masterCard" value="mastercard">
                    <template v-slot:label>
                      <img
                        style="height: 56px"
                        src="../assets/mastercard.png"
                        width="60px"
                        alt="visa"
                        class="payment-method"
                        :class="
                          paymentData.payment_method == 'mastercard'
                            ? 'active'
                            : ''
                        "
                      />
                    </template>
                  </v-radio>
                  <v-radio label="mada" value="mada">
                    <template v-slot:label>
                      <img
                        style="height: 56px"
                        src="../assets/Mada_Logo.svg"
                        width="60px"
                        alt="mada"
                        class="payment-method"
                        :class="
                          paymentData.payment_method == 'mada' ? 'active' : ''
                        "
                      />
                    </template>
                  </v-radio>
                </v-radio-group>
                <p class="error--text" v-if="paymentTypeMsg">
                  {{ paymentTypeMsg }}
                </p>

                <div class="duration-btns d-none d-sm-block">
                  <v-btn
                    class="monthly"
                    @click="updatePlanDuration('monthly')"
                    :class="!annually ? 'active' : ''"
                    large
                    >شهري</v-btn
                  >
                  <v-btn
                    class="annual"
                    :class="annually ? 'active' : ''"
                    @click="updatePlanDuration('annually')"
                    large
                    >سنوي</v-btn
                  >
                </div>
              </v-col>

              <v-col cols="12" sm="6">
                <h2>معلومات الدفع</h2>

                <v-form
                  v-model="valid"
                  ref="form"
                  class="mt-4"
                  id="payment_form"
                  action="https://api.moyasar.com/v1/payments.html"
                  method="POST"
                >
                  <input
                    type="hidden"
                    name="callback_url"
                    :value="
                      webUrl +
                      '/payment?planId=' +
                      planId +
                      '&type=' +
                      planType +
                      '&uuid=' +
                      uuid +
                      '&pageType=redirect' +
                      '&paymentId=' +
                      paymentId
                    "
                  />
                  <input
                    type="hidden"
                    name="publishable_api_key"
                    value="pk_test_wN6smrbt6zp7HvaHz56KenxVSihwpVefQbJft7RK"
                  />
                  <input
                    type="hidden"
                    name="amount"
                    v-if="planData && planData.annual && planData.monthly"
                    :value="
                      planType == 'annually'
                        ? parseInt(planData.annual.total * 100)
                        : parseInt(planData.monthly.total * 100)
                    "
                  />
                  <input
                    type="hidden"
                    name="description"
                    value="Mothaber Payment"
                  />
                  <input type="hidden" name="source[type]" value="creditcard" />

                  <input
                    type="hidden"
                    name="source[company]"
                    :value="paymentData.payment_method"
                  />
                  <v-row>
                    <v-col cols="9" class="pa-0">
                      <v-text-field
                        label="إسم حامل البطاقة ثلاثي"
                        name="source[name]"
                        v-model="paymentData.card_holder_name"
                        :rules="[
                          validationRules.required,
                          checkCardName(paymentData.card_holder_name),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-text-field
                        label="رقم البطاقة"
                        name="source[number]"
                        v-model="paymentData.card_number"
                        :rules="[
                          validationRules.required,
                          validationRules.numeric,
                          validationRules.maxLength16,
                          onlinePaymentValidation(paymentData.card_number),
                        ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-row>
                        <v-col
                          ><v-select
                            :items="months"
                            name="source[month]"
                            label="شهر الإنتهاء"
                            v-model="paymentData.exp_month"
                            :rules="[
                              validationRules.required,
                              checkDate(
                                paymentData.exp_year,
                                paymentData.exp_month
                              ),
                            ]"
                          ></v-select
                        ></v-col>
                        <v-col
                          ><v-select
                            label="سنة الإنتهاء"
                            name="source[year]"
                            :items="years"
                            v-model="paymentData.exp_year"
                            :rules="[
                              validationRules.required,
                              checkDate(
                                paymentData.exp_year,
                                paymentData.exp_month
                              ),
                            ]"
                          ></v-select
                        ></v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="9" class="pa-0">
                      <v-text-field
                        label="رمز التحقق من البطاقة"
                        name="source[cvc]"
                        v-model="paymentData.cvc"
                        :rules="[
                          validationRules.required,
                          validationRules.numeric,
                          validationRules.maxLength3,
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="9" class="pa-0">
                      <v-btn
                        class="main-btn login-btn mt-4 d-none d-md-inline-block"
                        @click.prevent="submitPayment"
                        >تاكيد الدفع</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="9"
            class="pa-0 payment-info"
            v-if="$route.query.pageType == 'redirect'"
          >
            <h1 class="main-color mb-0">الدفع</h1>
            <hr class="main-hr" />

            <div class="success-payment" v-if="paymentStatus == 'paid'">
              <h3 class="font-bold payment-confirm">تاكيد الدفع</h3>
              <p class="success--text mt-4">
                تم الدفع بنجاح
                <span class="">
                  <v-icon color="#00B282" class="rounded-icon" small
                    >mdi-check</v-icon
                  >
                </span>
              </p>
            </div>
            <div class="failed-payment" v-if="paymentStatus == 'failed'">
              <h3 class="font-bold payment-confirm">تاكيد الدفع</h3>
              <p class="error--text mt-4">
                فشلت عملية الدفع, اعد المحاولة مره اخرى
              </p>
              <v-btn
                class="main-btn login-btn"
                :to="`/payment?planId=${planId}&type=${planType}&uuid=${uuid}`"
                >اعد المحاولة</v-btn
              >
            </div>
          </v-col>
          <v-col cols="12" md="3" class="pa-sm-0">
            <div class="cart">
              <div class="cart-head text-center">
                <h3 class="main-color">اشتراكى</h3>
                <hr class="cart-hr" />
                <p
                  class="salon-name mb-8 second-color"
                  v-if="planData && planData.annual"
                >
                  <span> {{ planData.annual.name }} </span>
                </p>
              </div>
              <div class="cart-content mt-4">
                <div>
                  <v-row class="align-baseline" v-if="paymentStatus != 'paid'">
                    <v-col class="pa-0" cols="6">
                      <div class="title main-black">
                        <p class="mb-0">المصاريف</p>
                      </div>
                    </v-col>
                    <v-col class="pa-0" cols="6">
                      <p
                        class="price second-color mb-0"
                        v-if="planData && planData.annual && planData.monthly"
                      >
                        {{
                          planType == "annually"
                            ? planData.annual.fees
                            : planData.monthly.fees
                        }}
                        ر.س
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="cart-hr" v-if="paymentStatus != 'paid'" />
                  <v-row class="align-baseline" v-if="paymentStatus != 'paid'">
                    <v-col class="pa-0" cols="6">
                      <div class="title main-black">
                        <p class="mb-0">الضريبه</p>
                      </div>
                    </v-col>
                    <v-col class="pa-0" cols="6">
                      <p
                        class="price second-color mb-0"
                        v-if="planData && planData.annual && planData.monthly"
                      >
                        {{
                          planType == "annually"
                            ? planData.annual.taxes
                            : planData.monthly.taxes
                        }}
                        ر.س
                      </p>
                    </v-col>
                  </v-row>
                  <hr class="cart-hr" v-if="paymentStatus != 'paid'" />
                  <!-- ===========================when payment succes =========================== -->
                  <v-row
                    class="align-baseline"
                    v-if="pageType && paymentStatus == 'paid'"
                  >
                    <v-col class="pa-0" cols="6">
                      <div class="title main-black">
                        <p class="mb-0">الاجمالى</p>
                      </div>
                    </v-col>
                    <v-col class="pa-0" cols="6">
                      <p
                        class="price second-color mb-0"
                        v-if="planData && planData.annual && planData.monthly"
                      >
                        {{
                          planType == "annually"
                            ? planData.annual.total
                            : planData.monthly.total
                        }}
                        ر.س
                      </p>
                    </v-col>
                  </v-row>
                  <hr
                    class="cart-hr"
                    v-if="pageType && paymentStatus == 'paid'"
                  />
                  <v-row
                    class="align-baseline"
                    v-if="pageType && paymentStatus == 'paid'"
                  >
                    <v-col class="pa-0" cols="6">
                      <div class="title main-black">
                        <p class="mb-0">تاريخ الانتهاء</p>
                      </div>
                    </v-col>
                    <v-col class="pa-0" cols="6">
                      <p class="price second-color mb-0">
                        {{ planExpiryDate }}
                      </p>
                    </v-col>
                  </v-row>
                  <hr
                    class="cart-hr"
                    v-if="pageType && paymentStatus == 'paid'"
                  />

                  <v-row
                    class="align-baseline"
                    v-if="pageType && paymentStatus == 'paid'"
                  >
                    <v-col class="pa-0" cols="6">
                      <div class="title main-black">
                        <p class="mb-0">نوع الاشتراك</p>
                      </div>
                    </v-col>
                    <v-col class="pa-0" cols="6">
                      <p class="price second-color mb-0">
                        {{ planType == "annually" ? "سنوى" : "شهري" }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <!-- ====================== show branches if exist ================= -->
              <slot name="branches"></slot>

              <div class="cart-total mt-4" v-if="paymentStatus != 'paid'">
                <p class="total mb-0">الاجمالى :</p>
                <p
                  class="num"
                  v-if="planData && planData.annual && planData.monthly"
                >
                  {{
                    planType == "annually"
                      ? planData.annual.total
                      : planData.monthly.total
                  }}
                  ر.س
                </p>
              </div>
            </div>
            <div class="text-center">
              <v-btn
                class="main-btn login-btn mt-4 d-inline-block d-md-none"
                v-if="!$route.query.pageType"
                @click.prevent="submitPayment"
                >تاكيد الدفع</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- success dialog -->
    <v-dialog v-model="paymentDialog" max-width="500">
      <v-card>
        <v-card-title class="headline main-color"> انتظر </v-card-title>

        <div>
          <v-container style="padding: 1rem !important">
            <v-row>
              <v-col>
                <p class="text-center grey--text darken">
                  سيتم الانتقال لصفحة الدفع خلال
                </p>
                <p class="text-center grey--text darken">{{ countDown }}</p>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import pageHeader from "@/components/pageHeader.vue";
import { validationMixin } from "../mixins/validationMixin";
export default {
  name: "planPayment",
  mixins: [validationMixin],

  components: { pageHeader },
  data() {
    return {
      paymentTypeMsg: "",
      annually: true,
      valid: false,
      webUrl: window.location.origin,
      paymentDialog: false,
      countDown: 3,
      paymentId: "",
      planId: "",
      uuid: "",
      planType: "",
      years: [],
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      planData: {},
      paymentData: {
        id: "",
        uuid: "",
        plan_id: "",
        type: "",
        payment_method: "",
        card_holder_name: "",
        card_number: "",
        exp_month: "",
        exp_year: "",
        cvc: "",
      },
      paymentStatus: "",
      pageType: "",
      planExpiryDate: "",
    };
  },
  watch: {
    "paymentData.payment_method": {
      handler() {
        this.paymentTypeMsg = "";
      },
    },
  },
  methods: {
    getPlanData() {
      this.$http
        .get(this.base_url + "/front/getPlanFees/" + this.planId, {})
        .then((response) => {
          if (response.data.status.error == false) {
            this.planData = response.data.data;
          }
        });
    },
    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i <= currentYear + 8; i++) {
        this.years.push(i.toString());
      }
    },
    updatePlanDuration(duration) {
      this.annually = duration == "monthly" ? false : true;
      this.planType = duration;
      this.paymentData.type = duration;
    },
    onlinePaymentValidation(cardNumber) {
      if (cardNumber) {
        if (
          /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
            cardNumber
          ) ||
          /^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)
        ) {
          return true;
        } else {
          return "يجب ادخال رقم بطاقة صحيح";
        }
      }
    },

    checkCardName(cardName) {
      if (!new RegExp("\\w+\\s\\w+\\s\\w+.*").test(cardName)) {
        return "يجب ان يكون الاسم ثلاثى";
      } else {
        return true;
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        document.getElementById("payment_form").submit();
        this.paymentDialog = false;
      }
    },

    submitPayment() {
      if (this.valid == false || !this.paymentData.payment_method) {
        this.validate();
        this.paymentTypeMsg = !this.paymentData.payment_method
          ? "هذا الحقل مطلوب"
          : "";
      } else {
        //create payment
        this.$http
          .post(
            this.base_url + "/front/subscriptions/payment/create",
            this.paymentData,
            {
              headers: {
                locale: localStorage.locale,
                Authorization: "Bearer " + localStorage.token,
              },
            }
          )
          .then((res) => {
            if (res.data.status.error == false) {
              this.paymentId = res.data.data.payment_id;
              this.paymentDialog = true;
              this.countDownTimer();
              // finally submit form
            }
          });
      }
    },

    checkpayment() {
      let ourpaymentId = this.$route.query.paymentId;
      let moyasarPaymentId = this.$route.query.id;
      let status = this.$route.query.status;
      this.$http
        .post(
          this.base_url + "/front/subscriptions/checkPayment/" + ourpaymentId,
          {
            payment_id: moyasarPaymentId,
            status: status,
          },
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.paymentStatus = response.data.data.status;
          this.planExpiryDate = response.data.data.ex_data;
        });
    },

    getUserPaymentInfo() {
      this.$http
        .get(
          this.base_url + "/front/getPaymentInformation/?uuid=" + this.uuid,
          {
            headers: {
              locale: localStorage.locale,
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (
            response.data.data.card_holder_name &&
            response.data.data.card_number &&
            response.data.data.cvc
          ) {
            this.paymentData.id = response.data.data.id;
            this.paymentData.card_holder_name =
              response.data.data.card_holder_name;
            this.paymentData.card_number = response.data.data.card_number;
            this.paymentData.cvc = response.data.data.cvc;
            this.paymentData.exp_month = response.data.data.exp_month;
            this.paymentData.exp_year = response.data.data.exp_year;
            this.paymentData.payment_method = response.data.data.payment_method;
          }
        });
    },
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  mounted() {
    this.uuid = this.$route.query.uuid ? this.$route.query.uuid : "";
    this.planId = Number(this.$route.query.planId);
    this.paymentData.uuid = this.uuid;
    this.paymentData.plan_id = this.planId;
    this.planType = this.$route.query.type;

    this.annually = this.planType == "annually" ? true : false;
    this.paymentData.type = this.planType;
    this.generateYears();
    this.getPlanData();
    this.pageType = this.$route.query.pageType;
    if (this.$route.query.pageType) {
      this.checkpayment();
    }

    this.getUserPaymentInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.main-hr {
  margin: 1rem 0 !important;
}
#payment {
  .content {
    padding: 50px;
    .payment-info {
      .success-payment,
      .failed-payment {
        .payment-confirm {
          font-size: 1.5rem !important;
        }
        .success--text,
        .error--text {
          font-size: 1.2rem !important;
        }
        .rounded-icon {
          display: inline-block;
          border: 2px solid #00b282;
          border-radius: 50%;
          margin: 0 5px;
          font-size: 1rem !important;
          padding: 2px;
        }
      }
    }
    .duration-btns {
      max-width: 60%;
      // border: 1px solid #ccc;
      border-radius: 30px;
      display: flex;
      justify-content: space-between;
      margin-top: 2rem !important;
      box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      -webkit-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 1px 0px 11px 0px rgba(0, 0, 0, 0.3);
      .annual,
      .monthly {
        box-shadow: unset !important;
        background-color: transparent;
        border: 0 !important;
        border-radius: 30px;
        width: 50%;
      }

      .annual.active,
      .monthly.active {
        background-color: $second-color !important;
        color: #ffffff !important;
      }
    }
  }
}

.cart {
  width: 80%;
  margin: 0 auto;
  background-color: #f7f7f7;
  padding: 25px;
  border-radius: 0 0 0 40px;
  .cart-hr {
    margin: 12px 0 15px;
  }
  .cart-head {
    .salon-name {
      font-size: 1.25rem !important;
      font-weight: bold;
      .salon-icon {
        background-color: $main-color;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }
    }
    h3 {
      font-size: 2rem;
    }
    hr {
      width: 30%;
      margin: 0 auto;
    }
  }

  .salon-name {
    font-size: 14px;
  }
  .cart-total {
    .num {
      font-size: 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .content {
    padding: 12px !important;
    .duration-btns {
      max-width: 100% !important;
      margin-bottom: 1rem !important;
      .annual,
      .monthly {
        width: 50%;
      }
    }
  }

  .cart {
    width: 100%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .content {
    padding: 12px !important;
  }
  // .payment-method {
  //   width: 50px !important;
  // }
}
</style>
